<template lang="pug">
form.new-card(@submit.prevent="submitForm")
  AppFormInput.card-input(type="number" :label="$t('credit_card.number')" name="number" maxlength="16" required)
  AppRow
    AppFormInput(type="month" :label="$t('credit_card.expires')" name="exp_date" required)
    AppFormInput.mb-2(type="password" :label="$t('credit_card.cvc')" name="cvc" maxlength="4" required)
  AppCheckbox(:label="checkboxText" v-model="checkvalue" v-if="checkboxText")
  AppButton(:loading="loading" :label="buttonText" :disabled="loading || (!checkboxText ? false : !checkvalue)")
</template>

<script>
import AppRow from "@/components/AppRow";
import AppButton from "@/components/AppButton";
import AppFormInput from "@/components/Form/AppFormInput";
import AppCheckbox from "@/components/Form/AppCheckbox";
import { ref } from "vue";

import { encrypt } from "@/utils/crypto";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    buttonText: String,
    checkboxText: {
      type: String,
    },
  },
  components: { AppFormInput, AppButton, AppRow, AppCheckbox },
  emits: ["submit"],
  setup(props, { emit }) {
    const checkvalue = ref(false);

    const submitForm = async (e) => {
      try {
        const formData = {};
        const body = new FormData(e.target);

        body.forEach((value, key) => (formData[key] = value));

        const exp_date = new Date(formData.exp_date);

        formData.exp_year = encrypt("" + exp_date.getFullYear());
        formData.exp_month = encrypt("" + (exp_date.getMonth() + 1));
        formData.cvc = encrypt("" + formData.cvc);
        formData.number = encrypt("" + formData.number);
        delete formData.exp_date;

        emit("submit", formData);
      } catch (error) {
        console.log(error);
      }
    };

    return { submitForm, checkvalue };
  },
};
</script>

.
<style lang="scss" scoped>
.card-input ::v-deep input {
  @apply tracking-widest;
}
</style>
